<template lang="pug">
include ../../../configs/template
div.seafarerInfoList
  div.w-100
    +data-info('number', 'sailorDocument.number')
  div.w-100(v-if="sailorDocument.doctor")
    +data-info('medicalInstitution', 'medicalInstitutionByID(doctorByID(sailorDocument.doctor).medical_institution).value')
  div.w-100(v-else)
    +data-info('medicalInstitution', 'sailorDocument.other_medical_institution')
  div.w-100(v-if="sailorDocument.doctor")
    +data-info('doctor', 'doctorByID(sailorDocument.doctor).FIO')
  div.w-100(v-else)
    +data-info('doctor', 'sailorDocument.other_doctor')
  div.w-100
    +data-info('position', 'positionMedicalByID(sailorDocument.position)[nameLang]')
  div.w-100(v-if="sailorDocument.limitation")
    +data-info('limitation', 'medicalLimitationByID(sailorDocument.limitation)[nameLang]')
  div.w-100(v-else)
    +data-info('limitation', 'sailorDocument.other_limitation')
  div.w-50
    +data-info('dateIssue', 'getDateFormat(sailorDocument.date_start)')
  div.w-50
    +data-info('dateTermination', 'getDateFormat(sailorDocument.date_end)')
  div(v-if="checkAccess('document-author-view') && sailorDocument.created_by").w-50
    +data-info('recordAuthor', 'sailorDocument.created_by.name')
  div(v-if="checkAccess('document-author-view') && sailorDocument.created_by").w-50
    +data-info('createDate', 'sailorDocument.created_by.date')
  div(v-if="checkAccess('verification-author-view') && sailorDocument.verificated_by").w-50
    +data-info('verifier', 'sailorDocument.verificated_by.name')
  div(v-if="checkAccess('verification-author-view') && sailorDocument.verificated_by").w-50
    +data-info('verificationDate', 'sailorDocument.verificated_by.date')
  div.w-100
    +data-info('status', 'statusById(sailorDocument.status_document)[nameLang]')(:class="getStatus(sailorDocument.status_document)")
  div.col-12
    v-divider
  div(v-if="sailorDocument?.comments?.length").col-sm-12.text-left
    div.col-12.p-0
      h5.p-0.m-0 {{$t('rejectionReasonStatement')}}:
      v-divider
      div(v-for="(comment, index) in sailorDocument.comments" :key="comment.id").ml-4
        div
          +data-info('rejectionReasonStatement','getDirectoryObject({ id: comment.additional_info.reason, value: "rejectionReasons" })[nameLang]')
        div(v-if="checkAccess('backOffice')").pt-2.py-3
          +data-info('createdBy', 'comment.created_by.name')
        div.pt-2.py-3
          +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
        div.pt-2.py-3
          +data-info('reasonComment', 'comment.comment')
        v-divider(v-if="index !== sailorDocument.comments.length-1")
</template>

<script>
import { getDateFormat, getStatus } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    comments: { type: Array, default: () => ([]) },
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      getStatus,
      checkAccess,
      getDateFormat,
      rejectStatusId: 37
    }
  },
  computed: {
    ...mapGetters(['doctorByID', 'positionMedicalByID', 'medicalLimitationByID', 'statusById', 'medicalInstitutionByID',
      'rejectionReasonByID', 'getDirectoryObject']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    })
  }
}
</script>
